bgPrimary{
    background: blue !important;
}


/* Custom calendar styles */
.custom-calendar .fc-toolbar-title {
    color: #000; /* Default text color for headers */
  }
  
  /* Specific styles for August 2024 header */
  .custom-calendar .fc-toolbar-title {
    font-size: 1.5rem; /* Adjust font size if needed */
  }
  
  .custom-calendar .fc-col-header-cell {
    color: white; /* Default text color for headers */
    background-color: gray; /* Default background color for headers */
  }
  
  /* Change color for August 2024 header */
  .custom-calendar .fc-toolbar-title {
    color: red; /* Change to your preferred color */
  }

  /* Custom styles for PickList buttons */
