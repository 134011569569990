/* styles.css */
.button-new {
    background-color: #454545be;
    border: 1px solid #454545be;
    color: white;
}

.nav-divider {
    border-top: 1px solid #dee2e6;
    margin: 1rem 0;
  }
  
  .nav-heading {
    font-size: 0.875rem;
    color: #6c757d;
    text-transform: uppercase;
    margin: 0 1rem;
    padding-top: 0.5rem;
  }

.button-new:hover {
    background-color: #255a8e; /* Hover background color */
    border: 1px solid #255a8e;
}

.button-delete {
    background-color: #454545be;
    border: 1px solid #454545be;
    color: white;
}

.button-delete:hover {
    background-color: #255a8e;
    border: 1px solid #255a8e;
    color: white;
}

.button-export {
    background-color: #454545be;
    border: 1px solid #454545be;
    color: white;
}

.button-export:hover {
    background-color: #255a8e;
    border: 1px solid #255a8e;
    color: white;
}

.button-reorder {
    background-color: #777373;
    border: 1px solid #777373;
    color: white;
}

.button-reorder:hover {
    background-color: #255a8e;
    border: 1px solid #255a8e;
    color: white;
}

.button-filters {
    background-color: #777373;
    border: 1px solid #777373;
    color: white;
}

.button-filters:hover {
    background-color: #255a8e;
    border: 1px solid #255a8e;
    color: white;
}

.custom-button-edit {
    background-color: #777373; /* Change to desired color */
    border: 1px solid #777373;
    color: white;
}

.custom-button-edit:hover {
    background-color: #255a8e; /* Change to desired color */
    border: 1px solid #255a8e;
    color: white;
}

.custom-button-delete {
    background-color: #777373; /* Change to desired color */
    border: 1px solid #777373;
    color: white;
}

.custom-button-delete:hover {
    background-color: #255a8e; /* Change to desired color */
    border: 1px solid #255a8e;
    color: white;
}

.custom-button-edit:hover,
.custom-button-delete:hover {
    opacity: 0.8; /* Optional: add hover effect */
}

.form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 1rem; /* Space between fields */
}

.field {
    margin-bottom: 1rem;
}

.field label {
    display: block;
    margin-bottom: 0.5rem;
}

.custom-dialog-header .p-dialog-header {
    color: #3175b2; /* Replace with your desired color */
}

.cancel-button {
    background-color: #777373;
    color: white;
    border: 1px solid #777373;
}

.cancel-button:hover {
    background-color: #ed687a; /* Change to desired color */
    border: 1px solid #ed687a;
    color: white;
}

.edit-button {
    background-color: #777373;
    color: white;
    border: 1px solid #777373;
}
.edit-button:hover {
    background-color: #255a8e;
    color: white;
    border: 1px solid #255a8e;
}

.bold-label {
    font-weight: bold;
  }

  .field {
    margin-bottom: 16px; /* Adjust as needed */
    display: flex;
    flex-direction: column;
}

.field label {
    font-weight: bold;
    margin-bottom: 4px; /* Adjust spacing between label and input */
}

.field select,
.field input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.bold-label {
    font-weight: bold;
}



.button-export,
.button-reorder {
    /* Add any additional styles you need */
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

.gap-2 {
    gap: 0.5rem; /* Adjust as needed */
}


/* Ticket Remark */

.field {
  position: relative;
  margin-bottom: 1em;
}

.input-container {
  overflow: hidden;
  max-height: 100px; /* Adjust as needed for the default height */
}

.input-container textarea {
  width: 100%;
  box-sizing: border-box;
}

.read-more-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 5px;
}

.read-more-button:hover {
  text-decoration: none;
}

/* Add Ticket Button */


.add-ticket {
    background-color: green;
    border-color: green;
    color: white; /* Text color */
  }
  
  .add-ticket:hover {
    background-color: darkgreen; /* Hover background color */
    border-color: darkgreen; /* Hover border color */
  }
